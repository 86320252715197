import styled from 'styled-components';

export default styled.header`
  background: #202729 !important;
  position: fixed;
  width: 100%;
  z-index: 999;
  .header {    
    width: 900px;
    margin: auto;
    height: 90px;
    display: flex;
    align-items: center;
    .brand {
      flex: 1 1 auto;
      text-transform: uppercase;
      .name{
        font-weight: bold;
        font-size: 22px;
        padding-bottom: 5px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: -1px;
      }
      .work{
        font-size: 10px;
        letter-spacing: 1px;
      }
    }
    .navigation {
      flex: 0 0 auto;
      justify-content: flex-end;
      a {
        color: #FFFFFF;
        font-size: 28px;
        &:hover i {
          color: #3f51b5;
        }
      }
      ul {
        margin-bottom: 0;
        white-space: nowrap;
        li {
          position: relative;
          display: table-cell;
          width: 1%;
          width: 100px;
          text-align: center;
          a {
            cursor: pointer;
            color: #FFF;
            text-decoration: none;
            font-weight: 800;
            font-family: 'Open Sans', sans-serif;
            
            &.active, &:hover{
              color: #3f51b5;
            }
            &.active::after{
              content: '';
              position: absolute;
              width: 90%;
              height: 3px;
              background-color: #3f51b5;
              left: 0;
              right: 0;
              top: 52px;
              margin: auto;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px){
    .header {
      width: 100%;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 767px){
    .brand {
      text-align: center;
    }
    .navigation{
      display: none;
    }
    
  }
`;